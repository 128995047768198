//TODO: To generate the copy of form object to validate the errors ---
// export function generateErrorInitialState(formData: any) {
//   const generateErrorStructure = (data: any): any => {
//     if (Array.isArray(data)) {
//       return data.map((item: any) => generateErrorStructure(item));
//     } else if (typeof data === "object" && data !== null) {
//       const errorStructure: any = {};
//       for (const key in data) {
//         if (Array.isArray(data[key])) {
//           errorStructure[key] = data[key].map((item: any) => generateErrorStructure(item));
//         } else {
//           errorStructure[key] = "";
//         }
//       }

//       return errorStructure;
//     } else {
//       return "";
//     }
//   };

//   return generateErrorStructure(formData);
// }

export function generateErrorInitialState(formData: any) {
  const generateErrorStructure = (data: any): any => {
    if (Array.isArray(data)) {

      return data.map((item: any) => generateErrorStructure(item));
    } else if (typeof data === "object" && data !== null) {
      const errorStructure: any = {};
      for (const key in data) {
        if (Array.isArray(data[key])) {
          errorStructure[key] = data[key].map((item: any) => generateErrorStructure(item));
        } else {
          errorStructure[key] = typeof data[key] === 'string' ? '' : generateErrorStructure(data[key]);
        }
      }

      return errorStructure;
    } else {

      return "";
    }
  };

  return generateErrorStructure(formData);
}


// export function generateErrorInitialState(formData: any) {
//   const generateErrorStructure = (data: any): any => {
//     if (Array.isArray(data)) {

//       return data.map((item: any) => generateErrorStructure(item));
//     } else if (typeof data === "object" && data !== null) {
//       const errorStructure: any = {};
//       for (const key in data) {
//         const transformedKey = key.replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase()); // Transform field name
//         if (Array.isArray(data[key])) {
//           errorStructure[transformedKey] = data[key].map((item: any) => generateErrorStructure(item));
//         } else {
//           errorStructure[transformedKey] = typeof data[key] === 'string' ? '' : generateErrorStructure(data[key]);
//         }
//       }

//       return errorStructure;
//     } else {

//       return "";
//     }
//   };

//   return generateErrorStructure(formData);
// }



// //TODO: To make generative fields for single use of focus & blur error ---
// export const generativeFieldErrorCheck = (prevErrors: any, fieldName: any, value: any, index: number) => {
//   const updatedFireArms = Array.isArray(prevErrors.fireArms)
//     ? prevErrors.fireArms.map((armsError: any, i: number) =>
//       i === index
//         ? {
//           ...armsError,
//           [fieldName]: value === "" ? `${fieldName} is required` : "",
//         }
//         : armsError,
//     )
//     : [];

//   return { ...prevErrors, fireArms: updatedFireArms };
// };

export const generativeFieldErrorCheck = (prevErrors: any, fieldName: any, value: any, index: number) => {
  const updatedFireArms = Array.isArray(prevErrors.fireArms)
    ? prevErrors.fireArms.map((armsError: any, i: number) =>
      i === index
        ? {
          ...armsError,
          [fieldName.replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())]: value === "" ? `${fieldName} is required` : "",
        }
        : armsError,
    )
    : [];

  return { ...prevErrors, fireArms: updatedFireArms };
};

export const generativeFieldErrorCheckForManyTypes = (prevErrors: any, fieldName: any, value: any, index: number, fieldType: string) => {
  const updatedField = Array.isArray(prevErrors[fieldType])
    ? prevErrors[fieldType].map((fieldError: any, i: number) =>
      i === index
        ? {
          ...fieldError,
          [fieldName.replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())]: value === "" ? `${fieldName} is required` : "",
        }
        : fieldError,
    )
    : [];

  return { ...prevErrors, [fieldType]: updatedField };
};

// //TODO: To make generative fields for multi type of same looking fields use of focus & blur error ---
// export const generativeFieldErrorCheckForManyTypes = (prevErrors: any, fieldName: any, value: any, index: number, fieldType: string) => {
//   const updatedField = Array.isArray(prevErrors[fieldType])
//     ? prevErrors[fieldType].map((fieldError: any, i: number) =>
//       i === index
//         ? {
//           ...fieldError,
//           [fieldName]: value === "" ? `${fieldName} is required` : "",
//         }
//         : fieldError,
//     )
//     : [];

//   return { ...prevErrors, [fieldType]: updatedField };
// };

//TODO: Get the fields here to check it and for validate ---

// const validateForm = (formData: any) => {
//   const errors: any = {};

//   if (!formData || typeof formData !== 'object') {

//     return errors;
//   }

//   const emailPattern: any = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
//   const contactPattern: any = /^\d{10}$/;
//   const namePattern: any = /^[A-Za-z]{3,}$/;
//   const pincodePattern: any = /^\d{6}$/;

//   const calculateAge = (dob: string) => {
//     const today = new Date();
//     const birthDate = new Date(dob);
//     let age = today.getFullYear() - birthDate.getFullYear();
//     const monthDiff = today.getMonth() - birthDate.getMonth();

//     if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
//       age--;
//     }

//     return age;
//   };

//   Object.keys(formData).forEach((field) => {
//     if (Array.isArray(formData[field]) && formData[field].length > 0 && typeof formData[field][0] === 'object') {
//       const arrayErrors = formData[field].map((item: any) => validateForm(item));
//       const hasArrayErrors = arrayErrors.some((itemErrors: any) => Object.values(itemErrors).some(Boolean));

//       if (hasArrayErrors) {
//         errors[field] = arrayErrors;
//       } else {
//         errors[field] = '';
//       }
//     } else if (typeof formData[field] === 'object') {
//       errors[field] = validateForm(formData[field]);
//     } else if (formData[field] === '') {
//       errors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
//     } else if (field === 'email' && !emailPattern.test(formData[field])) {
//       errors[field] = 'Invalid email format';
//     } else if ((field === 'contactNumber' || field === 'alternateContactNumber') && (!(/^\d{10}$/.test(formData[field].toString())))) {
//       errors[field] = 'Invalid contact number format. Please enter exactly 10 digits';
//     } else if ((field === 'firstName' || field === 'lastName') && !namePattern.test(formData[field])) {
//       errors[field] = 'Minimum 3 characters required';
//     } else if (field === 'pincode' && !pincodePattern.test(formData[field])) {
//       errors[field] = 'Invalid pin code format';
//     } else if (field === 'dateOfBirth') {
//       const age = calculateAge(formData[field]);
//       if (age < 15) {
//         errors[field] = 'Minimum age requirement is 15 years';
//       } else {
//         errors[field] = '';
//       }
//     } else {
//       errors[field] = '';
//     }
//   });

//   return errors;
// };

// const validateForm = (formData: any) => {
//   const errors: any = {};

//   const formatFieldName = (fieldName: string) => {
//     return fieldName
//       // Remove underscores and insert a space between lowercase and uppercase letters
//       .replace(/_/g, ' ')
//       .replace(/([a-z])([A-Z])/g, "$1 $2")
//       // Capitalize the first letter
//       .replace(/^./, (str) => str.toUpperCase());
//   };

//   if (!formData || typeof formData !== "object") {
//     return errors;
//   }

//   const emailPattern: any = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
//   const contactPattern: any = /^\d{10}$/;
//   const namePattern: any = /^[A-Za-z]{3,}$/;
//   const pincodePattern: any = /^\d{6}$/;
//   const aadharPattern: any = /^\d{12}$/;

//   const calculateAge = (dob: string) => {
//     const today = new Date();
//     const birthDate = new Date(dob);
//     let age = today.getFullYear() - birthDate.getFullYear();
//     const monthDiff = today.getMonth() - birthDate.getMonth();

//     if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
//       age--;
//     }

//     return age;
//   };

//   Object.keys(formData).forEach((field) => {
//     const formattedField = formatFieldName(field);

//     if (Array.isArray(formData[field]) && formData[field].length > 0 && typeof formData[field][0] === "object") {
//       const arrayErrors = formData[field].map((item: any) => validateForm(item));
//       const hasArrayErrors = arrayErrors.some((itemErrors: any) => Object.values(itemErrors).some(Boolean));

//       if (hasArrayErrors) {
//         errors[field] = arrayErrors;
//       } else {
//         errors[field] = "";
//       }
//     } else if (typeof formData[field] === "object") {
//       errors[field] = validateForm(formData[field]);
//     } else if (formData[field] === "") {
//       errors[field] = `${formattedField} is required`;
//     } else if (field === "email" && !emailPattern.test(formData[field])) {
//       errors[field] = "Invalid email format";
//     }
//     else if ((field === "contactNumber" || field === "alternateContactNumber") && !/^\d{10}$/.test(formData[field].toString())) {
//       errors[field] = "Invalid contact number format. Please enter exactly 10 digits";
//     }
//     else if ((field === "school_phone" || field === "college_phone") && !/^\d{10}$/.test(formData[field])) {
//       errors[field] = "Invalid contact number format. Please enter exactly 10 digits";
//     }
//     // else if ((field === "contactNumber" || field === "alternateContactNumber") && (!/^\d{10}$/.test(formData[field].toString()) || /^(.)\1{9}$/.test(formData[field].toString()))) {
//     //   errors[field] = "Invalid contact number format. Please enter exactly 10 digits with different digits.";
//     // }
//     else if ((field === "firstName" || field === "lastName") && !namePattern.test(formData[field])) {
//       errors[field] = "Minimum 3 characters required";
//     } else if (field === "aadhar" && !aadharPattern.test(formData[field].toString())) {
//       errors[field] = "Invalid Aadhar number format. Please enter exactly 12 digits";
//     } else if (field === "pincode" && !pincodePattern.test(formData[field])) {
//       errors[field] = "Invalid pin code format";
//     } else if (field === "dateOfBirth") {
//       const age = calculateAge(formData[field]);
//       if (age < 15) {
//         errors[field] = "Minimum Age Requirement is 15 years";
//       } else {
//         errors[field] = "";
//       }
//     } else {
//       errors[field] = "";
//     }
//   });

//   return errors;
// };

// const validateForm = (formData: any) => {
//   const errors: any = {};

//   const formatFieldName = (fieldName: string) => {
//     return fieldName
//       .replace(/_/g, ' ')  // Replace underscores with spaces
//       .replace(/([a-z])([A-Z])/g, "$1 $2")  // Insert space between lowercase and uppercase letters
//       .replace(/^./, (str) => str.toUpperCase());  // Capitalize the first letter
//   };

//   // Regular expressions for patterns
//   const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
//   const contactPattern = /^\d{10}$/;
//   const namePattern = /^[A-Za-z]{3,}$/;
//   const pincodePattern = /^\d{6}$/;
//   const aadharPattern = /^\d{12}$/;

//   // Function to calculate age based on date of birth
//   const calculateAge = (dob: string) => {
//     const today = new Date();
//     const birthDate = new Date(dob);
//     let age = today.getFullYear() - birthDate.getFullYear();
//     const monthDiff = today.getMonth() - birthDate.getMonth();

//     if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
//       age--;
//     }

//     return age;
//   };

//   // Function to validate date range (entering date should be before leaving date)
//   const validateDateRange = (enteringDate: string, leavingDate: string) => {
//     const errors: any = {};

//     if (enteringDate && leavingDate && new Date(enteringDate) >= new Date(leavingDate)) {
//       errors.leaving_date = "Leaving date must be after entering date";
//     }

//     return errors;
//   };

//   // Validate each field in formData
//   if (formData && typeof formData === 'object') {
//     Object.keys(formData).forEach((field) => {
//       const formattedField = formatFieldName(field);

//       // Handle arrays of objects (if applicable)
//       if (Array.isArray(formData[field]) && formData[field].length > 0 && typeof formData[field][0] === "object") {
//         const arrayErrors = formData[field].map((item: any) => validateForm(item));
//         const hasArrayErrors = arrayErrors.some((itemErrors: any) => Object.values(itemErrors).some(Boolean));

//         if (hasArrayErrors) {
//           errors[field] = arrayErrors;
//         } else {
//           errors[field] = "";
//         }
//       } else if (formData[field] && typeof formData[field] === "object") {
//         errors[field] = validateForm(formData[field]);
//       } else if (formData[field] === "") {
//         errors[field] = `${formattedField} is required`;
//       } else if (field === "email" && !emailPattern.test(formData[field])) {
//         errors[field] = "Invalid email format";
//       } else if ((field === "contactNumber" || field === "alternateContactNumber") && !contactPattern.test(formData[field].toString())) {
//         errors[field] = "Invalid contact number format. Please enter exactly 10 digits";
//       } else if ((field === "school_phone" || field === "college_phone") && !contactPattern.test(formData[field])) {
//         errors[field] = "Invalid contact number format. Please enter exactly 10 digits";
//       } else if ((field === "firstName" || field === "lastName") && !namePattern.test(formData[field])) {
//         errors[field] = "Minimum 3 characters required";
//       } else if (field === "aadhar" && !aadharPattern.test(formData[field].toString())) {
//         errors[field] = "Invalid Aadhar number format. Please enter exactly 12 digits";
//       } else if (field === "pincode" && !pincodePattern.test(formData[field])) {
//         errors[field] = "Invalid pin code format";
//       } else if (field === "dateOfBirth") {
//         const age = calculateAge(formData[field]);
//         if (age < 15) {
//           errors[field] = "Minimum Age Requirement is 15 years";
//         } else {
//           errors[field] = "";
//         }
//       } else if (field === "school_entering_date" || field === "school_leaving_date") {
//         const enteringDate = formData.school_entering_date;
//         const leavingDate = formData.school_leaving_date;
//         const dateErrors = validateDateRange(enteringDate, leavingDate);
//         errors[field] = dateErrors[field];
//       } else if (field === "college_entering_date" || field === "college_leaving_date") {
//         const enteringDate = formData.college_entering_date;
//         const leavingDate = formData.college_leaving_date;
//         const dateErrors = validateDateRange(enteringDate, leavingDate);
//         errors[field] = dateErrors[field];
//       } else if (field === "valid_upto") {
//         const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format
//         if (new Date(formData[field]) < new Date(today)) {
//           errors[field] = "Valid upto date cannot be in the past";
//         } else {
//           errors[field] = "";
//         }
//       } else {
//         errors[field] = "";
//       }
//     });
//   }

//   return errors;
// };

const validateForm = (formData: any) => {
  const errors: any = {};

  const formatFieldName = (fieldName: string) => {
    return fieldName
      .replace(/_/g, ' ')  // Replace underscores with spaces
      .replace(/([a-z])([A-Z])/g, "$1 $2")  // Insert space between lowercase and uppercase letters
      .replace(/^./, (str) => str.toUpperCase());  // Capitalize the first letter
  };

  // Regular expressions for patterns
  const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  const contactPattern = /^\d{10}$/;
  const namePattern = /^[A-Za-z]{3,}$/;
  const pincodePattern = /^\d{6}$/;
  const aadharPattern = /^\d{12}$/;
  const bankNamePattern: any = /^[A-Za-z\s\-\.]{2,50}$/;
  const bankAccountNumberPattern: any = /^\d{9,18}$/;
  const bankIFSCcodePattern: any = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  const accountHolderNamePattern = /^[A-Za-z\s]{2,50}$/;
  const clubNamePattern = /^[A-Za-z\s_]{2,25}$/;
  const clubDomainNamePattern = /^[a-z]{2,20}$/;

  // Function to calculate age based on date of birth
  const calculateAge = (dob: string) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  // Function to validate date range (entering date should be before leaving date)
  const validateDateRange = (enteringDate: string, leavingDate: string) => {
    const errors: any = {};

    if (enteringDate && leavingDate && new Date(enteringDate) >= new Date(leavingDate)) {
      errors.leaving_date = "Leaving date must be after entering date";
    }

    return errors;
  };

  // Validate each field in formData
  if (formData && typeof formData === 'object') {
    Object.keys(formData).forEach((field) => {
      const formattedField = formatFieldName(field);

      // Handle arrays of objects (if applicable)
      if (Array.isArray(formData[field]) && formData[field].length > 0 && typeof formData[field][0] === "object") {
        const arrayErrors = formData[field].map((item: any) => validateForm(item));
        const hasArrayErrors = arrayErrors.some((itemErrors: any) => Object.values(itemErrors).some(Boolean));

        if (hasArrayErrors) {
          errors[field] = arrayErrors;
        } else {
          errors[field] = "";
        }
      } else if (formData[field] && typeof formData[field] === "object") {
        errors[field] = validateForm(formData[field]);
      }
      else if (typeof formData[field] === "string") {
        const trimmedValue = formData[field].trim();
        if (trimmedValue === "" && field !== "alternateContactPerson" && field !== "alternateEmail" && field !== "alternatePhoneNo") {
          errors[field] = `${formattedField} is required`;
        } else if (field === "email" && !emailPattern.test(formData[field])) {
          if (!errors[field]) errors[field] = "Invalid email format";
        } else if (field === "alternateEmail" && formData[field] && !emailPattern.test(formData[field])) {
          if (!errors[field]) errors[field] = "Invalid email format";
        } else if (field === "phoneNo" && !contactPattern.test(formData[field].toString())) {
          if (!errors[field]) errors[field] = "Invalid contact number format. Please enter exactly 10 digits";
        } else if (field === "alternatePhoneNo" && formData[field] && !contactPattern.test(formData[field].toString())) {
          if (!errors[field]) errors[field] = "Invalid contact number format. Please enter exactly 10 digits";
        }
        else if ((field === "firstName" || field === "lastName") && !namePattern.test(formData[field])) {
          if (!errors[field]) errors[field] = "Only letters allowed, minimum 2 characters required.";
        }
        else if (field === "clubName" && !clubNamePattern.test(formData[field])) {
          if (!errors[field]) errors[field] = "invalid club name, minimum 2 characters required.";
        }
        else if (field === "domainName" && !clubDomainNamePattern.test(formData[field])) {
          if (!errors[field]) errors[field] = "invalid club domain name, minimum 2 characters required.";
        }
        else if (field === "contactPerson" && !accountHolderNamePattern.test(formData[field])) {
          if (!errors[field]) errors[field] = "Only letters allowed, minimum 2 characters required.";
        }
        else if (field === "alternateContactPerson" && formData[field] && !accountHolderNamePattern.test(formData[field])) {
          if (!errors[field]) errors[field] = "Only letters allowed, minimum 2 characters required.";
        }
        else if (field === "aadhar" && !aadharPattern.test(formData[field].toString())) {
          if (!errors[field]) errors[field] = "Invalid Aadhar number format. Please enter exactly 12 digits";
        }
        else if (field === "pincode" && !pincodePattern.test(formData[field])) {
          if (!errors[field]) errors[field] = "Invalid pin code format";
        }
        else if (field === "dateOfBirth") {
          const age = calculateAge(formData[field]);
          if (age < 15) {
            if (!errors[field]) errors[field] = "Minimum Age Requirement is 15 years";
          }
        }
        // else if (field === "bankName" && !bankNamePattern.test(formData[field])) {
        //   if (!errors[field]) errors[field] = "Invalid bank name";
        // }
        // else if (field === "bankAccountNumber" && !bankAccountNumberPattern.test(formData[field])) {
        //   if (!errors[field]) errors[field] = "Invalid bank account number";
        // }
        // else if (field === "ifscCode" && !bankIFSCcodePattern.test(formData[field])) {
        //   if (!errors[field]) errors[field] = "Invalid IFSC code";
        // }
        // else if (field === "accountHolderName" && !accountHolderNamePattern.test(formData[field])) {
        //   if (!errors[field]) errors[field] = "Invalid account holder name";
        // }
        else if (field === "alternateContactPerson" && formData[field] && formData[field] === formData["contactPerson"]) {
          if (!errors[field]) errors[field] = "Alternate Contact Person cannot be the same as Contact Person";
        } else if (field === "alternateEmail" && formData[field] && formData[field] === formData["email"]) {
          if (!errors[field]) errors[field] = "Alternate Email cannot be the same as Email";
        } else if (field === "alternatePhoneNo" && formData[field] && formData[field] === formData["phoneNo"]) {
          if (!errors[field]) errors[field] = "Alternate Phone Number cannot be the same as Phone Number";
        } else {
          if (!errors[field]) errors[field] = "";
        }
      }
      // else if (formData[field] === "") {
      //   errors[field] = `${formattedField} is required`;
      // } 
      else if (field === "email" && !emailPattern.test(formData[field])) {
        errors[field] = "Invalid email format";
      } else if ((field === "contactNumber" || field === "alternateContactNumber") && !contactPattern.test(formData[field].toString())) {
        errors[field] = "Invalid contact number format. Please enter exactly 10 digits";
      } else if ((field === "school_phone" || field === "college_phone") && !contactPattern.test(formData[field])) {
        errors[field] = "Invalid contact number format. Please enter exactly 10 digits";
      } else if ((field === "firstName" || field === "lastName") && !namePattern.test(formData[field])) {
        errors[field] = "Minimum 3 characters required";
      } else if (field === "aadhar" && !aadharPattern.test(formData[field].toString())) {
        errors[field] = "Invalid Aadhar number format. Please enter exactly 12 digits";
      } else if (field === "pincode" && !pincodePattern.test(formData[field])) {
        errors[field] = "Invalid pin code format";
      } else if (field === "dateOfBirth") {
        const age = calculateAge(formData[field]);
        if (age < 15) {
          errors[field] = "Minimum Age Requirement is 15 years";
        } else {
          errors[field] = "";
        }
      }  // Validation for school and college dates
      else if (field === "school_entering_date" || field === "school_leaving_date") {
        console.log("School date validation?,.,.,.,.,.,.,.,.,.,.,.,.,.");
        const enteringDate = formData.school_entering_date;
        const leavingDate = formData.school_leaving_date;
        const dateErrors = validateDateRange(enteringDate, leavingDate);
        if (dateErrors.leaving_date) {
          console.log("dateErrors.leaving_date",dateErrors.leaving_date);
          errors[field] = dateErrors.leaving_date;
        }
      } else if (field === "college_entering_date" || field === "college_leaving_date") {
        const enteringDate = formData.college_entering_date;
        const leavingDate = formData.college_leaving_date;
        const dateErrors = validateDateRange(enteringDate, leavingDate);
        if (dateErrors.leaving_date) {
          errors[field] = dateErrors.leaving_date;
        }
      } else if (field === "valid_upto") {
        console.log("valid_upto Validation check");
        const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format
        if (new Date(formData[field]) <= new Date(today)) {
          errors[field] = "Valid upto date should be a future date";
        } else {
          errors[field] = "";
        }
      } else {
        errors[field] = "";
      }
    });
  }

  return errors;
};




export default validateForm;

/* 
    else if (field === 'passportNumber' && !passportPattern.test(formData[field])) {
      errors[field] = 'Invalid passport number format';
    }
*/
