import React, { useEffect } from "react";
import BackLink from "src/components/back-link";
import { Box, Stepper, Step, styled, StepLabel, Button, Stack, Grid, Typography, Avatar } from "@mui/material";
import APP_ROUTES from "src/routes/routePaths";
import "./style.scss";
import PageContainer from "src/components/page-container/PageContainer";
import userimg from "src/assets/images/profile/user-1.jpg";
import { useNavigate } from "react-router";
import Instruction from "./Instruction";
import RangeRegistrationForm from ".";
import Letter from "./Letter";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, AppState, dispatch } from "src/store/Store";

import PreviewForm from "./Preview";
import validateForm from "src/utils/FormValidate";
import { notifyMessage } from "src/utils/toastNotify";

import axiosServices from "src/utils/axios";
import { RangeRegister, RangeUpdation } from "../../api-call/rangeUse";
import { fetchClubAthleteRangeData } from "src/store/clubRegister/RangeRegisterView";
import { setReset } from "src/store/slices/rangeUsagesClub/RangeUsages";
import { deleteLocalFormData } from "src/services/localStorage/localServices";


const RangeRegisterSlider = () => {
  const navigate = useNavigate();
  const ProfileImage = styled(Box)(() => ({
    width: "145px",
    height: "145px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
  }));

  const allSteps = ["Instruction", "Range Registration", "Range Usage Application Form", "Applicant Info"];
  const limitedSteps = ["Instruction", "Range Usage Application Form"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [disabled, setDisabled] = React.useState(true);
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [onlyTwoSteps, setOnlyTwoSteps] = React.useState(false);
  const [marksheetFileName, setMarksheetFileName] = React.useState("");

  const RangeStore = useSelector((state: AppState) => state.rangeUsages);
  const { RangeShooter, isLoading } = useSelector((state: AppState) => state.clubAthleteRangeView);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchClubAthleteRangeData());
  }, [dispatch]);

  const isStepSkipped = (step: any) => skipped.has(step);
  const { confidentialDetails, educationalDetails, gaurdianDetails } = RangeStore;
  const { school, college, marksheet, student, studying_college } = educationalDetails;
  const {
    arm_application_status,
    arm_license,
    arm_license_proof,
    arm_type,
    business_details,
    details_of_course_handling_guns,
    details_of_sports_activity,
    if_applied,
    income,
    issues_by,
    license_no,
    other_informations,
    using_exp_firearms,
    valid_upto,
  } = confidentialDetails;
  const { college_name, college_address, college_entering_date, college_phone, college_leaving_date, college_passed } = college[0];
  const { school_name, school_address, school_entering_date, school_phone, school_leaving_date, school_passed } = school[0];
  const [{ age, default_gaurdian, name, occupation, present_address }] = gaurdianDetails;

  const firstSlideRequiredFields = {
    name,
    occupation,
    present_address,
    age,
    marksheet,
    income,
    business_details,
    // arm_license,
    arm_application_status,
    arm_license_proof,
    using_exp_firearms,
    details_of_course_handling_guns,
    details_of_sports_activity,
    other_informations,
    ...(student === "yes" && studying_college === "school" && {
      school_name,
      school_address,
      school_entering_date,
      school_leaving_date,
      school_passed,
      school_phone,
    }),
    ...((student !== "yes" || studying_college !== "school") && {
      school_name,
      school_address,
      school_entering_date,
      school_leaving_date,
      school_passed,
      school_phone,
      college_name,
      college_address,
      college_entering_date,
      college_leaving_date,
      college_passed,
      college_phone,
    }),
    ...(if_applied === "yes" && {
      license_no,
      issues_by,
      valid_upto,
    }),
  };

  const areAllFieldsFilled = (slide: any) => {
    return Object.entries(slide).every(([key, value]) => {

      if (key === 'marksheet') {
        console.log("marksheet", marksheet?.name, marksheet);
        if (marksheet == null) {
          console.log("marksheet is null or undefined");

          return false;
        } else if ((marksheet && marksheet?.name) || (typeof marksheet === 'object' && marksheet && typeof marksheet === 'string') || (typeof marksheet === "string" && marksheet) || (marksheet && marksheet?.url )) {
          console.log("marksheet", marksheet.name, marksheet);

          return true;
        } else if (typeof marksheet === 'object' && Object.keys(marksheet).length === 0) {
          console.log("marksheet is an empty object");

          return false;
        } else if (value == null || value == undefined || !(value instanceof File) || value.size === 0) {
          console.log("Invalid value", value);

          return false;
        }
      }

      if (key === 'arm_license_proof') {
        console.log("arm_license_proof", arm_license_proof?.name, arm_license_proof);
        if (arm_license_proof == null) {
          console.log("arm_license_proof is null or undefined");

          return false;
        } else if ((arm_license_proof && arm_license_proof?.name) || (typeof arm_license_proof === 'object' && arm_license_proof && typeof arm_license_proof === 'string') || (typeof arm_license_proof === "string" && arm_license_proof) || (arm_license_proof && arm_license_proof?.url )) {
          console.log("arm_license_proof", arm_license_proof.name, arm_license_proof);

          return true;
        } else if (typeof arm_license_proof === 'object' && Object.keys(arm_license_proof).length === 0) {
          console.log("arm_license_proof is an empty object");

          return false;
        } else if (value == null || value == undefined || !(value instanceof File) || value.size === 0) {
          console.log("Invalid value", value);

          return false;
        }
      }

      else {
        const { marksheet, arm_license_proof, conscent, ...fieldsToValidate } = slide;
        console.log("fieldsToValidate", fieldsToValidate);
        const errors = validateForm(fieldsToValidate);
        const hasErrors = Object.values(errors).some((value) => value !== undefined && value !== null && value !== "");
        console.log("hasErrors", hasErrors);

        return !hasErrors;
      }
    });
  };

  console.log("firstSlideRequiredFields", firstSlideRequiredFields);

  useEffect(() => {
    if (activeStep === 0) {
      setBtnDisabled(RangeShooter && (RangeShooter.range_usage_status === "pending" || RangeShooter.range_usage_status === "approved"));
    }
  }, [activeStep, RangeShooter]);

  useEffect(() => {
    if (activeStep === 1) {
      console.log("1 Slide data", firstSlideRequiredFields);
      const isSlideOneFieldsFilled = areAllFieldsFilled(firstSlideRequiredFields);
      console.log("isSlideOneFieldsFilled, setDisabled", isSlideOneFieldsFilled);
      setDisabled(!isSlideOneFieldsFilled);
    }
  }, [activeStep, firstSlideRequiredFields]);

  const handleRegister: any = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      handleNext();
    } catch (error) { }
  };

  const handlePreviewForm = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNext = async () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === steps.length - 1) {
      try {
        let result;
        console.log("RangeShooter", RangeShooter);

        if (RangeShooter && RangeShooter?.range_usage_status === "rejected") {
          console.log("Range Updation");
          result = await RangeUpdation(RangeStore, dispatch);
          console.log("Updation", result);
        } else if (!RangeShooter) {
          console.log("Range Register");
          result = await RangeRegister(RangeStore, dispatch);
          console.log("Register", result);
        }
        else {
          notifyMessage.error("Range registration failed");
        }
      } catch (error) {
        console.error("Error occurred during range registration:", error);
        notifyMessage.error("An error occurred while registering the range");
      }
    }

    setSkipped(newSkipped);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep - 1 == 0) {
        // dispatch(setReset());
      }

      return prevActiveStep - 1;
    });
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const handleSteps = (step: any) => {
    switch (step) {
      case 0:
        return <Instruction />;
      case 1:
        return <RangeRegistrationForm />;
      case 2:
        return <Letter />;
      case 3:
        return <PreviewForm />;
      default:
        break;
    }
  };

  const handleLimitedSteps = (step: any) => {
    switch (step) {
      case 0:
        return <Instruction />;
      case 1:
        return <Letter />;
      default:
        break;
    }
  };

  useEffect(() => {
    if (RangeShooter?.range_usage_status === "pending" || RangeShooter?.range_usage_status === "approved") {
      setOnlyTwoSteps(true);
    }
    else {
      setOnlyTwoSteps(false);
    }
  }, [RangeShooter && RangeShooter.range_usage_status]);

  const steps = RangeShooter && (RangeShooter.range_usage_status === "pending" || RangeShooter.range_usage_status === "approved") ? limitedSteps : allSteps;
  {
    activeStep === steps.length ? navigate(`${APP_ROUTES.ATHLETE_CLUB_RANGE_USAGES}`) : null;
  }

  const handleReduxState = () => {
    console.log("handleReduxState.........");
    dispatch(setReset());
    deleteLocalFormData("range");
  }

  return (
    <>
      <BackLink title="Back to range usage" route={`${APP_ROUTES.ATHLETE_CLUB_RANGE_USAGES}`} onClick={handleReduxState}/>
      <PageContainer title="Login" description="this is Login page">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} lg={12} xl={12} mb={2} display="flex" justifyContent="center" alignItems="center">
            <Box sx={{ width: "100%", boxShadow: "2px 2px 4px #ccc", background: "linear-gradient(317deg, rgba(255,255,254,1) 0%, rgba(249,251,253,1) 40%)" }} p={3}>
              <Stepper sx={{ paddingBottom: "20px" }} activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: { optional?: React.ReactNode } = {};
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              {activeStep === steps.length ? null : (
                <>
                  <Box>{onlyTwoSteps ? handleLimitedSteps(activeStep) : handleSteps(activeStep)}</Box>
                  <Stack direction="row" justifyContent="center" spacing={3} mt={4}>
                    {activeStep === 1 && (RangeShooter?.range_usage_status !== "pending" && RangeShooter?.range_usage_status !== "approved") ? (
                      <>
                        <Button size="large" onClick={handleBack} variant="contained" color="inherit" sx={{ width: '150px' }}>
                          <Typography variant="subtitle2" textAlign="center" width="100%">
                            Previous
                          </Typography>
                        </Button>
                        <Button size="large" variant="contained" color="inherit" onClick={handleRegister} disabled={disabled} sx={{ width: '150px' }}>
                          <Typography variant="subtitle2" textAlign="center" width="100%">
                            {RangeShooter && RangeShooter.range_usage_status === "rejected" ? "Update" : "Register"}
                          </Typography>
                        </Button>
                      </>
                    ) : activeStep === 0 && (RangeShooter?.range_usage_status === "pending" || RangeShooter?.range_usage_status === "approved") ? (
                      <>
                        <Button size="large" onClick={handleBack} disabled={activeStep === 0} variant="contained" color="inherit" sx={{ width: '150px' }}>
                          <Typography variant="subtitle2" textAlign="center" width="100%">
                            Previous
                          </Typography>
                        </Button>
                        <Button size="large" variant="contained" disabled={activeStep === steps.length - 1} color="inherit" onClick={handlePreviewForm} sx={{ width: '150px' }}>
                          <Typography variant="subtitle2" textAlign="center" width="100%">
                            Next
                          </Typography>
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button size="large" disabled={activeStep === 0} onClick={handleBack} variant="contained" color="inherit" sx={{ width: '150px' }}>
                          <Typography variant="subtitle2" textAlign="center" width="100%">
                            Previous
                          </Typography>
                        </Button>
                        <Button size="large" onClick={handleNext} disabled={btnDisabled} variant="contained" color={activeStep === steps.length - 1 ? "success" : "primary"} sx={{ width: '150px' }}>
                          <Typography variant="subtitle2" textAlign="center" width="100%">
                            {activeStep === steps.length - 1 ? "Finish" : "Next"}
                          </Typography>
                        </Button>
                      </>
                    )}
                  </Stack>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </PageContainer>
    </>
  );
};

export default RangeRegisterSlider;
