import axiosServices from "src/utils/axios";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { notifyMessage } from "src/utils/toastNotify";

//Range Selections

export const getAdminAllRentedRanges = async () => {
    try {
        const response = await axiosServices.get(`/range/athletesrentedranges/`);

        if (response.status === 200) {
            const ClubDetail = response.data.data

            console.log(ClubDetail, "shooter dataaaaaaa");

            return ClubDetail;
        } else {
            console.error("Failed to fetch RentedRangesl:", response);
            throw new Error("Failed to fetch RentedRanges");
        }
    } catch (error) {
        console.error("Failed to fetch RentedRanges:", error);
        throw new Error("Failed to fetch RentedRanges");
    }
};


export const getAdminSingleRentedRanges = async (id: any) => {
    try {
        const response = await axiosServices.get(`/range/athletesrentedranges/${id}`);

        if (response.status === 200) {
            const ClubDetail = response.data.data.range_utilization_data

            return ClubDetail;
        } else {
            console.error("Failed to fetch single RentedRangesl:", response);
            throw new Error("Failed to fetch RentedRanges");
        }
    } catch (error) {
        console.error("Failed to fetch RentedRanges:", error);
        throw new Error("Failed to fetch RentedRanges");
    }
};


export const createRangeDuration = async (data: any) => {
    try {
        const response = await axiosServices.post("/range/createrangeperiod", data);
        console.log(response, "admin range");

        if (response.status === 200) {
            const result = response.data;

            return result;
        } else {
            console.error("Unexpected response:", response);
            // throw new Error("Failed to create Range ");
        }
    } catch (error: any) {
        console.error("Failed to create Range Duration", error);
        if(error.response && error.response.data){
            notifyMessage.error(error.response.data.message);
        }
        // throw new Error("Failed to create Range ");
    }
};


export const createRange = async (data: any) => {
    try {
        const response = await axiosServices.post("/range/createrange", data);
        console.log(response, "range Duration");

        if (response.status === 200) {
            const result = response.data;

            return result;
        } else {
            console.error("Unexpected response:", response);
            throw new Error("Failed to create Range Duration");
        }
    } catch (error) {
        console.error("Failed to create Range Duration", error);
        throw new Error("Failed to create Range Duration");
    }
};



export const deleteRangeById = async (rangeId: any) => {
    try {
        const response = await axiosServices.delete(`/range/deleterange/${rangeId}`);

        if (response.status === 200) {
            return { success: true, message: "Range deleted successfully" };
        } else {
            console.error("Failed to delete Range:", response);

            return { success: false, message: "Failed to delete Range" };
        }
    } catch (error) {
        console.error("Failed to delete Range:", error);

        return { success: false, message: "Failed to delete Range" };
    }
};


export const deleteRangeDurById = async (rangeDurId: any) => {
    try {
        const response = await axiosServices.delete(`/range/deleterangeperiod/${rangeDurId}`);

        if (response.status === 200) {
            return { success: true, message: "Range Duration successfully" };
        } else {
            console.error("Failed to delete Range Duaration:", response);

            return { success: false, message: "Failed to delete Range Duaration" };
        }
    } catch (error) {
        console.error("Failed to delete Range Duration:", error);

        return { success: false, message: "Failed to delete Range Duaration" };
    }
};


export const updateRangeById = async (rangeId: any, rangeData: any) => {
    try {
        const response = await axiosServices.put(`/range/editrange/${rangeId}`, rangeData);
        console.log(response, "update range");

        if (response.status === 200) {
            const result = response.data;

            return result;
        } else {
            console.error("Unexpected response:", response);
            throw new Error("Failed to update range");
        }
    } catch (error) {
        console.error("Failed to update range", error);
        throw new Error("Failed to update range");
    }
};


//LockerRoom

export const createLockerType = async (data: any) => {
    try {
        const response = await axiosServices.post(`${process.env.REACT_APP_BASE_URL}/range/addlockertype`, data);
        console.log(response, "Locker Type");

        if (response.status === 200) {
            const result = response.data;

            return result;
        } else {
            console.error("Unexpected response:", response);
            throw new Error("Failed to create Locker Type");
        }
    } catch (error) {
        console.error("Failed to create Locker Type", error);
        throw new Error("Failed to create Locker Type");
    }
};


export const getAdminAllLockerType = async () => {
    try {
        const response = await axiosServices.get(`${process.env.REACT_APP_BASE_URL}/range/viewlockertype`);

        if (response.status === 200) {
            const ClubDetail = response.data.data

            console.log(ClubDetail, "LockerType");

            return ClubDetail;
        } else {
            console.error("Failed to fetch LockerType:", response);
            throw new Error("Failed to fetch LockerType");
        }
    } catch (error) {
        console.error("Failed to fetch LockerType:", error);
        throw new Error("Failed to fetch LockerType");
    }
};


export const updateLockrTypeById = async (lockerTypeId: any, lockerData: any) => {
    try {
        const response = await axiosServices.put(`${process.env.REACT_APP_BASE_URL}/range/updatelockertype/${lockerTypeId}`, lockerData);
        console.log(response, "update lockerType");

        if (response.status === 200) {
            const result = response.data;

            return result;
        } else {
            console.error("Unexpected response:", response);
            throw new Error("Failed to update lockerType");
        }
    } catch (error) {
        console.error("Failed to update lockerType", error);
        throw new Error("Failed to update lockerType");
    }
};


export const deleteLockerTypeById = async (lockerTypeId: any) => {
    try {
        const response = await axiosServices.delete(`/range/removelockertype/${lockerTypeId}`);

        if (response.status === 200) {
            return { success: true, message: "LockerType successfully" };
        } else {
            console.error("Failed to delete LockerType:", response);

            return { success: false, message: "Failed to delete LockerType" };
        }
    } catch (error) {
        console.error("Failed to delete LockerType :", error);

        return { success: false, message: "Failed to delete LockerType" };
    }
};


export const getAdminAllLockerRoomNo = async (lockerTypeId: any) => {
    try {
        const response = await axiosServices.get(`/range/viewlockerroom/${lockerTypeId}`);

        if (response.status === 200) {
            const ClubDetail = response.data.data

            console.log(ClubDetail, "Locker Room no");

            return ClubDetail;
        } else {
            console.error("Failed to fetch Locker Room no:", response);
            throw new Error("Failed to fetch Locker Room no");
        }
    } catch (error) {
        console.error("Failed to fetch Locker Room no:", error);
        throw new Error("Failed to fetch Locker Room no");
    }
};



export const createLockerRoomNo = async (data: any) => {
    try {
        const response = await axiosServices.post("/range/addlockerroom", data);
        console.log(response, "LockerRoomNo");

        if (response.status === 200) {
            const result = response.data;

            return result;
        } else {
            console.error("Unexpected response:", response);
            throw new Error("Failed to create LockerRoomNo");
        }
    } catch (error) {
        console.error("Failed to create LockerRoomNo", error);
        throw new Error("Failed to create LockerRoomNo");
    }
};


export const updateLockerRoomById = async (lockerId: any, lockerData: any) => {
    try {
        const response = await axiosServices.put(`/range/updatelockerroom/${lockerId}`, lockerData);
        console.log(response, "update LockerRoom");

        if (response.status === 200) {
            const result = response.data;

            return result;
        } else {
            console.error("Unexpected response:", response);
            throw new Error("Failed to update LockerRoom");
        }
    } catch (error) {
        console.error("Failed to update LockerRoom", error);
        throw new Error("Failed to update LockerRoom");
    }
};


export const deleteLockerRoomById = async (lockerId: any) => {
    try {
        const response = await axiosServices.delete(`/range/deletelockerroom/${lockerId}`);

        if (response.status === 200) {
            return { success: true, message: "LockerRoom successfully" };
        } else {
            console.error("Failed to delete LockerRoom:", response);

            return { success: false, message: "Failed to delete LockerRoom" };
        }
    } catch (error: any) {
        console.error("Failed to delete LockerRoom :", error);

        return { success: false, message: error?.response?.data?.message };
    }
};


export const getAllAthletesRentedLocker = async () => {
    try {
        const response = await axiosServices.get(`/range/viewallathletesrentedlocker`);

        if (response.status === 200) {
            const ClubDetail = response.data.data

            console.log(ClubDetail, "AthletesRentedLocker");

            return ClubDetail;
        } else {
            console.error("Failed to fetch AthletesRentedLocker:", response);
            throw new Error("Failed to fetch AthletesRentedLocker");
        }
    } catch (error) {
        console.error("Failed to fetch AthletesRentedLocker:", error);
        throw new Error("Failed to fetch AthletesRentedLocker");
    }
};


export const updateStatusById = async (lockerId: any, lockerData: any) => {
    try {
        const response = await axiosServices.patch(`${process.env.REACT_APP_BASE_URL}/range/reviewlockerrequest/${lockerId}`, lockerData);
        console.log(response, "updateStatus LockerRoom");

        if (response.status === 200) {
            const result = response.data;

            return result;
        } else {
            console.error("Unexpected response:", response);
            throw new Error("Failed to updateStatus");
        }
    } catch (error: any) {
        console.error("Failed to update updateStatus", error);
        const errorMessage = error.response?.data?.message?.[0]?.message || "Not able to approve";
        notifyMessage.error(errorMessage);
        throw new Error("Failed to update updateStatus");
    }
};