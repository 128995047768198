import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, MenuItem, Modal, Stack, Tab, Tooltip, useMediaQuery } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import BlankCard from "src/components/shared/BlankCard";
import TableHead from "src/components/table-head";
import DataTable from "src/components/table/TableComponent";
import { GridColDef } from "@mui/x-data-grid";
import APP_ROUTES from "src/routes/routePaths";
import { useNavigate } from "react-router";
import AlertBox from "src/layouts/full/shared/AlertBox/AlertBox";
import { IconPencil, IconTrash } from "@tabler/icons";
import DeleteIcon from "@mui/icons-material/Delete";
import PageContainer from "src/components/page-container/PageContainer";
import { generateErrorInitialState } from "src/utils/FormValidate";
import { notifyMessage } from "src/utils/toastNotify";
import CustomFormLabel from "src/utils/theme-elements/CustomFormLabel";
import CustomTextField from "src/utils/theme-elements/CustomTextField";
import CustomSelect from "src/utils/theme-elements/CustomSelect";
import { createLockerType, deleteLockerTypeById, deleteRangeById, getAdminAllLockerType, getAllAthletesRentedLocker, updateLockrTypeById } from "../api-call/adminRangeUse";
import BackLink from "src/components/back-link";
import { TabList } from "@mui/lab";
import LockerAllocation from "./LockerAllocation";
import { IconEye } from "@tabler/icons-react";
import { IconLayoutGridAdd } from "@tabler/icons-react";
import { useLocation } from 'react-router';
import { PreventWheelChange } from "src/utils/PreventWheelChange";

// interface AdminLockerProps {
//   propValue?: any;
// }

const AdminLocker = () => {
  const [value, setValue] = useState("2");
  const [alertOpen, setAlertOpen] = useState(false);
  const [ranges, setRanges] = useState<any[]>([]);
  const [deletedRanges, setDeletedRanges] = useState<any[]>([]);
  const [rentedLocker, setRentedLocker] = useState<any[]>([]);
  const [presentLockerRoomName, setPresentLockerRoomName] = useState<any[]>([]);
  //const [selectedRangeId, setSelectedRangeId] = useState("");
  const [selectedLockerDeleteId, setSelectedLockerDeleteId] = useState("");
  const [selectedLockerUpdateId, setSelectedLockerUpdateId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [addopen, setAddOpen] = useState(false);
  const [isDuplicateError, setIsDuplicateError] = useState(false);
  const [initialLockerTypeName, setInitialLockerTypeName] = useState<string>('');

  const navigate = useNavigate();
  const location = useLocation();

  const propValue = (location.state as any)?.propValue || {};
  console.log("propValue", propValue);

  useEffect(() => {
    if (value === "2") {
      if (propValue == '1') {
        console.log("propValue changed:", propValue);
        setValue(propValue);
      }
    }
  }, []);

  const initialFormData: any = {
    locker_type_name: "",
    locker_durations: [
      {
        locker_period_id: null,
        locker_period_duration: "",
        locker_amount: "",
        is_active: true,
        utilization_count: 0,
      },
    ],
  };

  const [formData, setFormData] = useState<any>(initialFormData);
  const [rows, setRows] = useState(initialFormData.locker_durations);

  const errorInitialState: any = generateErrorInitialState(formData);
  const [error, setError] = useState(errorInitialState);

  const fetchRange = async (): Promise<any[]> => {
    try {
      const response = await getAdminAllLockerType();
      const lockerTypeIdsAndNames = response.map((lockerType: any) => ({
        locker_type_id: lockerType.locker_type_id,
        locker_type_name: lockerType.locker_type_name
      }));
      console.log("lockerType", response);

      console.log("lockerTypeIdsAndNames", lockerTypeIdsAndNames);
      setPresentLockerRoomName(lockerTypeIdsAndNames);

      return response;
    } catch (error) {
      console.error("Failed to fetch all Locker Type", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchRange();
        setRanges(response);

        console.log("setRanges", response);

        const rentedLockerUser = await getAllAthletesRentedLocker();
        console.log(rentedLockerUser, "hii user locker");
        setRentedLocker(rentedLockerUser);
      } catch (error) {
        console.error("Failed to fetch ranges", error);
      }
    };
    fetchData();
  }, [selectedLockerDeleteId, selectedLockerUpdateId]);

  // const handleEdit = (id: any) => {
  //   setEditMode(true);
  //   const selectedRange = ranges.find((comp: any) => comp.locker_type_id === id);
  //   if (selectedRange) {
  //     setInitialLockerTypeName(selectedRange.locker_type_name);
  //     const rangePeriodDurations = selectedRange.locker_room_period_details.map((period: any) => ({
  //       locker_period_id: period.locker_period_id,
  //       locker_period_duration: period.locker_period_duration,
  //       locker_amount: period.locker_amount,
  //       is_active: period.is_active,
  //       utilization_count: period.utilization_count,
  //     }));
  //     setFormData({
  //       locker_type_name: selectedRange.locker_type_name,
  //       locker_durations: rangePeriodDurations,
  //     });
  //     console.log("rangePeriodDurations", rangePeriodDurations);
  //     setRows(rangePeriodDurations);
  //     setSelectedLockerUpdateId(id);
  //     setAddOpen(true);
  //   }
  // };

  const handleEdit = (id: any) => {
    setEditMode(true);
    const selectedRange = ranges.find((comp: any) => comp.locker_type_id === id);
    if (selectedRange) {
      setInitialLockerTypeName(selectedRange.locker_type_name);

      // Check if locker_room_period_details is defined and not empty
      const rangePeriodDurations = selectedRange.locker_room_period_details?.length > 0
        ? selectedRange.locker_room_period_details.map((period: any) => ({
          locker_period_id: period.locker_period_id,
          locker_period_duration: period.locker_period_duration,
          locker_amount: period.locker_amount,
          is_active: period.is_active,
          utilization_count: period.utilization_count,
        }))
        : [];

      setFormData({
        locker_type_name: selectedRange.locker_type_name,
        locker_durations: rangePeriodDurations,
      });
      console.log("rangePeriodDurations", rangePeriodDurations);
      setRows(rangePeriodDurations);
      setSelectedLockerUpdateId(id);
      setAddOpen(true);
    }
  };

  const handleOpenAlert = (id: any) => {
    setSelectedLockerDeleteId(id);
    //setSelectedRangeId(id);
    setAlertOpen(true);
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  const handleConfirmAction = async () => {
    try {
      if (selectedLockerDeleteId) {
        const response = await deleteLockerTypeById(selectedLockerDeleteId);
        if (response.success) {
          notifyMessage.success("Locker Type deleted successfully");
          const response = await fetchRange();
          setRanges(response);
        } else {
          notifyMessage.error(response.message);
        }
      }
    } catch (error) {
      notifyMessage.error("Failed to delete LockerType");
    } finally {
      handleCloseAlert();
    }
  };

  const handleOpen = (id: any) => {
    console.log(id, "add  next page id");
    navigate(`${APP_ROUTES.ADMIN_CLUB_LOCKER_ROOM}/addlocker/${id}`);
  };

  const handleAddOpen = () => {
    setAddOpen(true);
  };

  const resetFormAndError = () => {
    setFormData(initialFormData);
    setError(errorInitialState);
    setRows(initialFormData.locker_durations);
  };

  const handleAddClose = () => {
    setInitialLockerTypeName('');
    setEditMode(false);
    setAddOpen(false);
    setDeletedRanges([]);
    setSelectedLockerUpdateId("");
    resetFormAndError();
    setIsDuplicateError(false);
  };

  const handleAddRow = () => {
    // Check if all existing rows are filled
    if (rows.length > 12) {
      return;
    };

    const allRowsFilled = rows.every((row: any) => row.locker_period_duration !== "" && row.locker_amount !== "");

    if (!allRowsFilled) {
      notifyMessage.error("Please fill all rows before adding a new one");
      wholeError();

      return;
    }

    // If all rows are filled, add a new row
    setRows([...rows, { locker_period_duration: "", locker_amount: "" }]);
    setFormData((prevData: any) => ({
      ...prevData,
      locker_durations: [...prevData.locker_durations, {
        locker_period_id: null,
        locker_period_duration: "",
        locker_amount: "",
        is_active: 1,
        utilization_count: 0,
      },],
    }));
    setError((prevErrors: any) => ({
      ...prevErrors,
      locker_durations: [...prevErrors.locker_durations, { locker_period_duration: "", locker_amount: "" }],
    }));
  };

  // const handleRemoveRow = (indexToRemove: number) => {
  //   if (editMode) {
  //     const removedRow = formData.locker_durations[indexToRemove];
  //     setDeletedRanges((prevDeletedRanges: any[]) => [
  //       ...prevDeletedRanges,
  //       { ...removedRow, is_active: 0 },
  //     ]);
  //   }
  //   setRows((prevRows: any) => prevRows.filter((_: any, index: any) => index !== indexToRemove));
  //   setFormData((prevData: any) => ({
  //     ...prevData,
  //     locker_durations: prevData.locker_durations.filter((_: any, index: any) => index !== indexToRemove),
  //   }));
  //   setError((prevErrors: any) => ({
  //     ...prevErrors,
  //     locker_durations: prevErrors.locker_durations.filter((_: any, index: any) => index !== indexToRemove),
  //   }));
  // };

  const handleRemoveRow = (indexToRemove: number) => {
    if (editMode) {
      const removedRow = formData.locker_durations[indexToRemove];
      if (removedRow.locker_period_id !== null) {
        setDeletedRanges((prevDeletedRanges: any[]) => [
          ...prevDeletedRanges,
          { ...removedRow, is_active: 0 },
        ]);
      }
    }

    setRows((prevRows: any) => prevRows.filter((_: any, index: any) => index !== indexToRemove));

    setFormData((prevData: any) => ({
      ...prevData,
      locker_durations: prevData.locker_durations.filter(
        (_: any, index: any) => index !== indexToRemove
      ),
    }));

    setError((prevErrors: any) => ({
      ...prevErrors,
      locker_durations: prevErrors.locker_durations.filter(
        (_: any, index: any) => index !== indexToRemove
      ),
    }));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    console.log(newValue, "tab value");
    setValue(newValue);
  };

  const handleChange = (index: any, field: any) => (e: any) => {
    const { value, type, checked } = e.target;
    const newValue = field === "locker_amount" ? parseInt(value, 10) : value;

    if (field === "locker_type_name") {
      const isDuplicateName = presentLockerRoomName.some((item: any) => {
        if (editMode && formData.locker_type_name === initialLockerTypeName) {
          return false;
        }

        return item.locker_type_name === value;
      });

      if (isDuplicateName) {
        setIsDuplicateError(true);
        console.error("Locker type name already exists. Please choose a different name.");
      } else {
        setIsDuplicateError(false);
      }

      setFormData((prevData: any) => ({
        ...prevData,
        locker_type_name: value,
      }));

      return;
    }

    // Check for duplicate locker_period_duration
    if (field === "locker_period_duration") {
      const isDuplicate = formData.locker_durations.some((item: any, i: any) => i !== index && item.locker_period_duration === newValue);
      if (isDuplicate) {
        notifyMessage.warning("Duration already selected. Please choose a different value.");
        console.error("Duplicate value detected. Please choose a different value.");

        return;
      }
    }

    setRows((prevRows: any) => prevRows.map((row: any, i: any) => (i === index ? { ...row, [field]: type === "checkbox" ? checked : newValue } : row)));

    setFormData((prevData: any) => ({
      ...prevData,
      locker_durations: prevData.locker_durations.map((item: any, i: any) => (i === index ? { ...item, [field]: type === "checkbox" ? checked : newValue } : item)),
    }));
  };

  const singleError = (fieldName: any, index: any) => {
    if (fieldName === "locker_type_name") {
      const newErrors = validateForm({ locker_type_name: formData.locker_type_name });
      setError((prevErrors: any) => ({
        ...prevErrors,
        locker_type_name: newErrors.locker_type_name,
      }));
    } else {
      const newErrors = validateForm({ [fieldName]: formData.locker_durations[index][fieldName] });
      setError((prevErrors: any) => ({
        ...prevErrors,
        locker_durations: prevErrors.locker_durations.map((err: any, i: any) => (i === index ? { ...err, [fieldName]: newErrors[fieldName] } : err)),
      }));
    }
  };

  const wholeError = () => {
    const newErrors = validateForm(formData);
    setError(newErrors);
  };

  const validateForm = (formData: any) => {
    const errors: any = {};

    if (!formData.locker_type_name) {
      errors.locker_type_name = "This field is required";
    } else {
      const isDuplicateName = presentLockerRoomName.some((item: any) => {
        if (editMode && formData.locker_type_name === initialLockerTypeName) {
          return false;
        }

        return item.locker_type_name === formData.locker_type_name;
      });

      if (isDuplicateName) {
        setIsDuplicateError(true);
        errors.locker_type_name = "Locker type name already exists. Please choose a different name.";
      } else {
        setIsDuplicateError(false);
      }
    }

    if (formData.locker_durations) {

      formData.locker_durations.forEach((duration: any, index: any) => {
        // Validate locker_period_duration
        if (!duration.locker_period_duration) {
          if (!errors.locker_durations) errors.locker_durations = [];
          errors.locker_durations[index] = {
            ...errors.locker_durations[index],
            locker_period_duration: "This field is required"
          };
        }
        if (!duration.locker_amount) {
          if (!errors.locker_durations) errors.locker_durations = [];
          errors.locker_durations[index] = { ...errors.locker_durations[index], locker_amount: "This field is required" };
        }
      });
    }

    return errors;
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const isAnyRowEmpty = formData.locker_durations.some((row: any) => row.locker_period_duration === "" || row.locker_amount === "");
    const isNewRowEmpty = formData.locker_durations.slice(0, -1).some((row: any) => row.locker_period_duration === "" || row.locker_amount === "");

    if (isAnyRowEmpty || isNewRowEmpty) {
      notifyMessage.error("Please fill all the rows before submitting.");
      wholeError();

      return;
    }

    if (isDuplicateError) {
      notifyMessage.error("Please fix the errors before submitting.");

      return;
    }

    try {
      if (selectedLockerUpdateId) {
        const updatedFormData = {
          ...formData,
          locker_durations: [
            ...formData.locker_durations,
            ...deletedRanges
          ]
        };
        const updateLockerType = await updateLockrTypeById(selectedLockerUpdateId, updatedFormData);
        console.log(updateLockerType, "updated");
        setDeletedRanges([]);
        notifyMessage.success("Range updated Successfully");
      } else {
        const saveLockerType = await createLockerType(formData);
        console.log(saveLockerType, "saved");
        notifyMessage.success("Locker Type created Successfully");
      }

      const response = await fetchRange();
      setRanges(response);
      handleAddClose();
    } catch (error) {
      console.error("Error:", error);
      notifyMessage.error("Failed to save range");
    }
  };

  console.log(formData, "hiii data");

  const CompetitionColumns: GridColDef[] = [
    { field: "sr_no", headerName: "Sr.No", flex: 0.5 },
    {
      field: "lockerType", headerName: "Locker Type Name", flex: 1.5,
      renderCell: (params) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params.value} </div>)
    },
    {
      field: "addLocker",
      headerName: "Add locker",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <Tooltip title="Add locker">
            <IconButton sx={{ cursor: "pointer" }} onClick={() => handleOpen(parseInt(params.row.id))}>
              <IconLayoutGridAdd size="22" stroke={1.4} />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <IconButton sx={{ cursor: "pointer" }} onClick={() => handleEdit(params.row.id)}>
              <IconPencil size="22" stroke={1.4} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton sx={{ cursor: "pointer" }} onClick={() => handleOpenAlert(params.row.id)}>
              <IconTrash size="22" stroke={1.4} />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const LockerTypeRows = (ranges ?? []).map((ran, index) => ({
    sr_no: index + 1,
    id: ran.locker_type_id,
    lockerType: ran.locker_type_name,
  }));
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));

  const handleShooterView = (id: any) => {
    console.log(id, "shooterid");
    navigate(`${APP_ROUTES.ADMIN_CLUB_LOCKER_ROOM}/appliedshooter/${id}`);
  };

  const columns: GridColDef[] = [
    { field: "sr_no", headerName: "Sr.No", flex: 1 },
    {
      field: "shooterName", headerName: "Shooter Name", flex: 2,
      renderCell: (params) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params.value} </div>)
    },
    {
      field: 'email', headerName: 'Email', flex: 2,
      renderCell: (params) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params.value} </div>)
    },
    {
      field: 'phone', headerName: 'Phone', flex: 2,
      renderCell: (params) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params.value} </div>)
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <Tooltip title="View">
            <IconButton sx={{ cursor: "pointer" }} onClick={() => handleShooterView(params.row.id)}>
              <IconEye size="22" stroke={1.4} />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const LockerRented = (rentedLocker ?? []).map((ran, index) => ({
    sr_no: index + 1,
    id: ran.id,
    shooterName: `${ran.first_name} ${ran.last_name}`,
    email: ran.email,
    phone: ran.phone,
  }));

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <BackLink title="Back to range availability" route={`${APP_ROUTES.ADMIN_CLUB_RANGE_USAGES}`} />
      <PageContainer>
        <BlankCard>
          <TabContext value={value}>
            <Box
              sx={{
                flexShrink: 0,
                border: "0",
                borderLeft: "1px",
                borderStyle: "solid",
                right: "0",
                background: (theme) => theme.palette.background.paper,
                boxShadow: "0",
                position: lgUp ? "relative" : "absolute",
                borderColor: (theme) => theme.palette.divider,
              }}
            >
              <TabList onChange={handleTabChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons="auto">
                <Tab label="Shooter Purchase List" value="2" />
                <Tab label="Locker Room Creation" value="1" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <PageContainer title="Locker Page" description="this is Locker Page">
                <BlankCard>
                  <Box>
                    <Stack sx={{ background: "#ECF2FF", borderRadius: "6px" }} direction="row" justifyContent="space-between" alignItems="center" p={2}>
                      <TableHead title="Locker Room Type Creation" />
                      <Button onClick={handleAddOpen} variant="contained" color="primary">
                        Add Locker Type
                      </Button>
                    </Stack>
                  </Box>
                  <AlertBox
                    open={alertOpen}
                    disabled={false}
                    title="Confirm Delete"
                    buttonText="Delete"
                    message={<>Are you sure want to delete this data?</>}
                    onClose={handleCloseAlert}
                    onConfirm={handleConfirmAction}
                  />
                  <DataTable rows={LockerTypeRows} columns={CompetitionColumns} tableId="table24" />
                </BlankCard>
              </PageContainer>
            </TabPanel>
            <TabPanel value="2">
              {/* <LockerAllocation /> */}
              <PageContainer title="Renewal" description="this is user page">
                <BlankCard>
                  <TableHead title="Locker Applied Shooter" />
                  <DataTable rows={LockerRented} columns={columns} tableId="table25" />
                </BlankCard>
              </PageContainer>
            </TabPanel>
          </TabContext>
        </BlankCard>
      </PageContainer>

      <Dialog open={addopen} onClose={handleAddClose} fullWidth>
        <DialogTitle>Add Locker Duration</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} mr={2}>
                <CustomFormLabel sx={{ mt: 0 }} htmlFor={`locker_type_name`}>
                  Locker Type Name <b style={{ color: "red" }}>*</b>
                </CustomFormLabel>
                <CustomTextField
                  fullWidth
                  name={`locker_type_name`}
                  value={formData.locker_type_name}
                  error={!!error.locker_type_name}
                  helperText={error.locker_type_name ? error.locker_type_name : ' '}
                  onChange={handleChange(0, "locker_type_name")}
                  onBlur={() => singleError("locker_type_name", 0)}
                />
              </Box>
              <Button style={{ marginTop: '8px' }} size="large" variant="contained" disabled={rows.length > 12} onClick={handleAddRow}>
                + Add
              </Button>
            </Box>
          </Box>
          <Box>
            {rows.map((row: any, index: any) => (
              <Box key={index}>
                <Box display="flex">
                  <Box flexGrow={1} mr={2}>
                    <CustomFormLabel sx={{ mt: 0 }} htmlFor={`locker_period_duration-${index}`}>
                      Locker Period Duration {index + 1}<b style={{ color: "red" }}>*</b>
                    </CustomFormLabel>
                    <CustomTextField
                      fullWidth
                      name={`locker_period_duration-${index}`}
                      select
                      value={formData.locker_durations[index]?.locker_period_duration || ""}
                      error={!!error.locker_durations[index]?.locker_period_duration}
                      helperText={error.locker_durations[index]?.locker_period_duration ? error.locker_durations[index]?.locker_period_duration : ' '}
                      onChange={handleChange(index, "locker_period_duration")}
                      onBlur={() => singleError("locker_period_duration", index)}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 250, // Set the desired max height here
                            },
                          },
                        },
                      }}
                    >
                      {/* <MenuItem value="">
                        <em>None</em>
                      </MenuItem> */}
                      {[...Array(12)].map((_, i) => (
                        <MenuItem key={i} value={i + 1}>
                          {i + 1} {i == 0 ? 'Month' : 'Months'}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </Box>
                  <Box flexGrow={1} mr={2}>
                    <CustomFormLabel sx={{ mt: 0 }} htmlFor={`locker_amount-${index}`}>
                      Locker Amount <b style={{ color: "red" }}>*</b>
                    </CustomFormLabel>
                    <CustomTextField
                      fullWidth
                      name={`locker_amount-${index}`}
                      type="number"
                      value={formData.locker_durations[index]?.locker_amount || ""}
                      error={!!error.locker_durations[index]?.locker_amount}
                      helperText={error.locker_durations[index]?.locker_amount ? error.locker_durations[index]?.locker_amount : ' '}
                      onChange={handleChange(index, "locker_amount")}
                      onKeyPress={handleKeyPress}
                      onWheel={PreventWheelChange}
                      onBlur={() => singleError("locker_amount", index)}
                    />
                  </Box>
                  {rows.length !== 1 && (
                    formData.locker_durations[index]?.utilization_count === 0 ? (
                      <IconButton
                        onClick={() => handleRemoveRow(index)}
                        color="error"
                        style={{ marginTop: '-10px' }}
                      >
                        <IconTrash size="22" stroke={1.4} />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => notifyMessage.warning("Cannot delete this item because it is currently in use.")}
                        color="error"
                        style={{ marginTop: '-10px' }}
                      >
                        <IconTrash size="22" stroke={1.4} />
                      </IconButton>
                    )
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions style={{ marginRight: '15px' }}>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={handleAddClose} color="error" sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              {editMode ? 'Update' : 'Submit'}
            </Button>
          </Box>
        </DialogActions>
      </Dialog >
    </>
  );
};

export default AdminLocker;
