import { Avatar, Box, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import BackLink from "src/components/back-link";
import APP_ROUTES from "src/routes/routePaths";
import userimg from "src/assets/images/profile/user-1.jpg";
import { dispatch } from "src/store/Store";
import { fetchTenantByIdStart, fetchTenantByIdSuccess } from "src/store/reducers/TenentSlice";

import "./style.scss";
import { useParams } from "react-router";
import { AppDispatch, AppState } from "src/store/Store";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { fetchClubAthleteData, setShooterId } from "src/store/clubRegister/ClubAthleteViewEdit";
import { getClubDetailById, getClubId } from "../../api-call/rangeUse";

const ProfileImage = styled(Box)(() => ({
  backgroundImage: "linear-gradient(#50B2FC,#F44C66)",
  borderRadius: "50%",
  width: "145px",
  height: "145px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 auto",
}));

const Letter = () => {
  const { athleteId } = useParams();
  const dispatch: AppDispatch = useDispatch();

  const [clubDetail, setClubDetail] = useState<any>(null);
  const [clubId, setClubId] = useState<any>();
  const { athleteResponse, isLoading } = useSelector((state: AppState) => state.viewClubAthlete);
  const { tenant, loading, error }: any = useSelector((state: AppState) => state.tenent);
  console.log(tenant, "tenantdata");
  console.log(athleteId, athleteResponse, isLoading);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    console.log(token);
    if (token) {
      const decodedToken: any = jwtDecode(token);
      console.log(decodedToken, "decoded");
      if (decodedToken) {
        const { userId } = decodedToken;
        dispatch(setShooterId(userId));
        dispatch(fetchClubAthleteData({ id: userId }));
      }
    }
  }, [dispatch]);

  const {
    //aadhar_card,
    address,
    city,
    club_name,
    dOB,
    education,
    email,
    event,
    first_name,
    gender,
    last_name,
    membership_type,
    phone,
    pincode,
    safety_course,
    state_name,
    state_unit,
  } = athleteResponse;

  // const { name } = clubDetail;

  console.log(clubDetail, "hiir");

  const date = new Date().toISOString().split("T")[0];

  console.log(athleteResponse, "from mappings");

  useEffect(() => {
    dispatch(fetchTenantByIdStart(clubId));
  }, [dispatch, clubId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ClubIds = await getClubId(club_name);
        console.log(ClubIds.id, "idd");
        setClubId(ClubIds.id);
      } catch (error) {
        console.error("Failed to fetch ClubDetails", error);
      }
    };

    fetchData();
  }, [club_name, dispatch]);

  return (
    <>
      <div className="affilates">
        <Box sx={{ mt: 2 }} padding={1} marginLeft={1.5}>
          <Typography variant="h5" textAlign="center">
            Range Usage Application form
          </Typography>
        </Box>
        <br></br>
        <div className="range-data">
          <div className="profile-display">
            <div>
              <p>
                To,
                <br />
                The General Secretary
                <br />
                {tenant.name}
                <br />
                {tenant.address} {tenant.addressTwo} {tenant.addressThree}
                <br />
                <span>
                  {tenant.city} - {tenant.pincode}
                </span>
              </p>
            </div>
            <Box>
              <ProfileImage>
                <Avatar
                  src={athleteResponse.profile_photo}
                  alt={userimg}
                  sx={{
                    borderRadius: "50%",
                    width: "140px",
                    height: "140px",
                    border: "4px solid #fff",
                  }}
                />
              </ProfileImage>
            </Box>
          </div>
          <br />
          <p>
            Subject: Permission for use of <b>{club_name}</b> Shooting ranges.
          </p>
          <br />
          <p>Madam/Sir,</p>
          <br></br>
          <p>
            I
            <b>
              {" "}
              {first_name} {last_name}{" "}
            </b>{" "}
            presently residing at{" "}
            <b>
              {address} ,{city} ,{state_name} ,{pincode} ,
            </b>{" "}
            Mobile no. <b>{phone}</b> , Email ID <b>{email}</b> desirous of using the <b>{club_name}</b> Shooting ranges for target practise.
          </p>
          <br></br>
          <p>
            I am a member of following Rifle Club: <b>{club_name}</b>
          </p>
          <p>
            Name of the Rifle Club : <b>{club_name}</b>
          </p>
          <p>
            Membership type / No : <b>{membership_type}</b>
          </p>
          <p>
            Member upto : <b>{membership_type}</b>
          </p>
          <p>
            Hence I may be extended the <b>{club_name}</b> shooting range facility for target practise.
          </p>
          <p>I am willing to pay the requisite charges for the same. Thanking you in anticipation.</p>
          <br />
          <br />
          <p>Yours Truly,</p>
          <br />
          <p>
            <b>
              {first_name} {last_name}
            </b>
          </p>
          <br />
          <br />
          <p>
            Date:{" "}
            <b>
              <span className="date">{date}</span>
            </b>
          </p>
        </div>
      </div>
    </>
  );
};

export default Letter;
