import React, { useEffect, useState, useMemo } from "react";
import BackLink from "src/components/back-link";
import {
  Box,
  Stepper,
  Step,
  styled,
  StepLabel,
  Button,
  Stack,
  Grid,
  Typography,
  Avatar, Dialog, DialogActions, DialogContent, DialogTitle, IconButton
} from "@mui/material";
import APP_ROUTES from "src/routes/routePaths";
import "./style.scss";
import PageContainer from "src/components/page-container/PageContainer";
import userimg from "src/assets/images/profile/user-1.jpg";
import { useNavigate, useParams } from "react-router";
import axiosServices from "src/utils/axios";
import { jwtDecode } from "jwt-decode";
import "./style.scss";
import VisibilityIcon from "@mui/icons-material/Visibility";

const ProfileImage = styled(Box)({
  width: "145px",
  height: "145px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 auto",
});

const steps = ["Range Usage Application Form", "Applicant Info"];

interface ShooterDetails {
  [key: string]: any;
}

interface ShooterMainDetails {
  [key: string]: any;
}

interface AdminMainDetails {
  [key: string]: any;
}

const RangeSlider: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [shooterDetails, setShooterDetails] = useState<ShooterDetails>({});
  const [shooterMainDetails, setShooterMainDetails] = useState<ShooterMainDetails>({});
  const [adminMainDetails, setAdminMainDetails] = useState<AdminMainDetails>({});

  const [selectedDocument, setSelectedDocument] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);

  const isStepSkipped = (step: number) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const fetchClubAdminDetails = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      if (token) {
        const decodedToken: any = jwtDecode(token);
        if (decodedToken) {
          const { state } = decodedToken;
          const clubDetails = await axiosServices.get(`/range/${state}`);
          const clubId = clubDetails.data.id;
          const response = await axiosServices.get(`/tenants/get-tenant/${clubId}`);
          console.log("response.data.tenant:::::::", response.data.tenant);
          setAdminMainDetails(response.data.tenant)
        }
      }
    } catch (error) {
      console.error("Error fetching admin main data:", error);
    }
  };


  const fetchShooterMainDetails = async () => {
    try {
      const response = await axiosServices.get(`/club/athlete/${id}`);
      setShooterMainDetails(response.data);
      console.log("Shooter main detail dataaaa::::::", response.data)
    } catch (error) {
      console.error("Error fetching shooter main data:", error);
    }
  };

  const fetchShooterDetails = async () => {
    try {
      const response = await axiosServices.get(`/range/registershooterlist/${id}`);
      const user_data = JSON.parse(JSON.stringify(response.data.data))
      setShooterDetails(user_data);
      console.log("Shooter detail data:", user_data);
    } catch (error) {
      console.error("Shooter detail data::::::", error);
    }
  };

  const getFormattedDate = (datetimevar: string | Date) => {
    const date = new Date(datetimevar);
    if (isNaN(date.getTime())) {
      return '';
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const handleViewDocument = (documentType: string) => {
    setSelectedDocument(documentType);
    console.log(documentType, "file type");
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    fetchShooterDetails();
    fetchShooterMainDetails();
    fetchClubAdminDetails();
  }, []);

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <div className="affilates">
            <div className="range-heading">
              <h3 className="text-center">Range Usage Application form</h3>
            </div>
            <br />
            <div className="range-data">
              <div className="profile-display">
                <div>
                  <p>
                    To,
                    <br />
                    The General Secretary
                    <br />
                    {adminMainDetails.name}
                    <br />
                    {adminMainDetails.address}
                    <br />
                    {adminMainDetails.city}
                    <br />
                    {adminMainDetails.state}-{adminMainDetails.pincode}
                  </p>
                </div>
                <Box>
                  <ProfileImage>
                    <Avatar
                      src={userimg}
                      alt="User"
                      sx={{ width: "140px", height: "140px", border: "2px solid #fff" }}
                    />
                  </ProfileImage>
                </Box>
              </div>
              <br />
              <p>Subject: Permission for use of MRA Shooting ranges.</p>
              <br />
              <p>Madam/Sir,</p>
              <p>
                I <b>{shooterDetails.first_name} {shooterDetails.last_name}</b> presently residing at
                <b> {shooterMainDetails.address}, {shooterMainDetails.city}, {shooterMainDetails.state_name}</b> Mobile no.
                <b> {shooterMainDetails.phone}</b> Email ID <b>{shooterMainDetails.email}</b> desirous of using the
                MRA Shooting ranges for target practise.
              </p>
              <p>I am a member of following Rifle Club:</p>
              <p>
                Name of the Rifle Club : <b>{adminMainDetails.name}</b>
              </p>
              <p>
                Membership type / No : <b>MRA AFFILIATED</b>
              </p>
              <p>
                Member upto : <b>{shooterMainDetails.membership_type}</b>
              </p>
              <p>Hence I may be extended the MRA shooting range facility for target practise.</p>
              <p>I am willing to pay the requisite charges for the same. Thanking you in anticipation.</p>
              <br />
              <br />
              <p>Yours Truly,</p>
              <p>
                <b>{shooterDetails.first_name} {shooterDetails.last_name}</b>
              </p>
              <p>
                Date:{" "}
                <b>
                  <span className="date">{getFormattedDate(shooterDetails.created_at)}</span>
                </b>
              </p>
            </div>
          </div>
        );
      case 1:
        const schoolDetailsArray = Object.values(JSON.parse(shooterDetails.school_details));
        const collegeDetailsArray = Object.values(JSON.parse(shooterDetails.college_details));
        const familyDetailsArray = Object.values(JSON.parse(shooterDetails.family_details));
        const armDetailsArray = JSON.parse(shooterDetails.arm_details);

        return (
          <>
            <div className="comm_form3">
              <div className="range-heading">
                <h3>
                  {adminMainDetails.name}
                  <br />
                  {adminMainDetails.name} Ranges, {adminMainDetails.address} {adminMainDetails.addressTwo} {adminMainDetails.addressThree} {adminMainDetails.city} - {adminMainDetails.pincode}
                  <br />
                  Tel. {adminMainDetails.contactNumber}
                  {/* {adminMainDetails.alternateContactNumber != "null" && ` / ${adminMainDetails.alternateContactNumber}`} */}
                  <br />
                  <span className="caps">Private &amp; Confidential</span>
                </h3>
              </div>
              <div className="range-data">
                <ol>
                  <li className="spacer">
                    <p>
                      1. Name of Applicant :{" "}
                      <b>
                        {" "}
                        {shooterDetails.first_name} {shooterDetails.last_name}
                      </b>
                    </p>
                    <p>
                      Address:{" "}
                      <b>
                        {shooterMainDetails.address}, {shooterMainDetails.state_name}, {shooterMainDetails.city}-{shooterMainDetails.pincode}
                      </b>
                    </p>
                    <p>
                      Tel / E-mail Id:{" "}
                      <b>
                        {shooterMainDetails.phone} / {shooterMainDetails.email}{" "}
                      </b>
                    </p>
                  </li>
                  <li className="spacer">
                    <p>
                      2. Qualification: SSC/HSC/Post Graduate/Other qualifications
                      <br />
                      Education qualification showing places of education with year in school
                    </p>
                    <table className="table table-bordered darkborder" id="school_preview_tbl">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Address</th>
                          <th>Date of Entering</th>
                          <th>Date of Leaving</th>
                          <th>Examinations passed</th>
                          <th>Telephone Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {schoolDetailsArray.map((schoolItem: any, index: any) => (
                          <tr key={index}>
                            <td>{schoolItem.school_name || "-"}</td>
                            <td>{schoolItem.school_address || "-"}</td>
                            <td>{schoolItem.date_entering || "-"}</td>
                            <td>{schoolItem.school_leaving_date || "-"}</td>
                            <td>{schoolItem.school_passed || "-"}</td>
                            <td>{schoolItem.school_phone || "-"}</td>
                          </tr>
                        ))}
                      </tbody>

                    </table>
                    <div className="college_div">
                      Education qualification showing places of education with year in college
                      <table className="table table-bordered darkborder" id="college_preview_tbl">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Date of Entering</th>
                            <th>Date of Leaving</th>
                            <th>Examinations passed</th>
                            <th>Telephone Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          {collegeDetailsArray.map((collegeItem: any, index: any) => (
                            <tr key={index}>
                              <td>{collegeItem.college_name || "-"}</td>
                              <td>{collegeItem.college_address || "-"}</td>
                              <td>{collegeItem.college_entering_date || "-"}</td>
                              <td>{collegeItem.college_leaving_date || "-"}</td>
                              <td>{collegeItem.college_passed || "-"}</td>
                              <td>{collegeItem.college_phone || "-"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <p>
                      Self attested copy of marksheet/certificate:
                      <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={() => handleViewDocument("marksheet")}>
                        <VisibilityIcon />
                      </IconButton>
                      {/* <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                        {selectedDocument === "arm_license_proof" && (
                          <DialogContent>
                            {shooterDetails.arm_proof ? (
                              <img src={shooterDetails.arm_proof} alt="arm_license_proof" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                            ) : (
                              <div>Image not available...</div>
                            )}
                          </DialogContent>
                        )}
                        {selectedDocument === "marksheet" && (
                          <DialogContent>
                            {shooterDetails.marksheet ? (
                              <img src={shooterDetails.marksheet} alt="marksheet" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                            ) : (
                              <div>Image not available...</div>
                            )}
                          </DialogContent>
                        )}
                        <DialogActions>
                          <Button onClick={handleCloseDialog}>Close</Button>
                        </DialogActions>
                      </Dialog> */}
                      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                        {selectedDocument === "arm_license_proof" && (
                          <DialogContent>
                            {shooterDetails.arm_proof ? (
                              shooterDetails.arm_proof instanceof File ? (
                                <img
                                  src={URL.createObjectURL(shooterDetails.arm_proof)}
                                  alt="arm_license_proof"
                                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                                  onLoad={(e) => URL.revokeObjectURL((e.target as HTMLImageElement).src)} // Clean up the object URL after the image has loaded
                                />
                              ) : (
                                <img
                                  src={shooterDetails.arm_proof}
                                  alt="arm_license_proof"
                                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                                />
                              )
                            ) : (
                              <div>Image not available...</div>
                            )}
                          </DialogContent>
                        )}
                        {selectedDocument === "marksheet" && (
                          <DialogContent>
                            {shooterDetails.marksheet ? (
                              shooterDetails.marksheet instanceof File ? (
                                <img
                                  src={URL.createObjectURL(shooterDetails.marksheet)}
                                  alt="marksheet"
                                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                                  onLoad={(e) => URL.revokeObjectURL((e.target as HTMLImageElement).src)} // Clean up the object URL after the image has loaded
                                />
                              ) : (
                                <img
                                  src={shooterDetails.marksheet}
                                  alt="marksheet"
                                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                                />
                              )
                            ) : (
                              <div>Image not available...</div>
                            )}
                          </DialogContent>
                        )}
                        <DialogActions>
                          <Button onClick={handleCloseDialog}>Close</Button>
                        </DialogActions>
                      </Dialog>

                    </p>
                  </li>
                  <li className="spacer">
                    <p>
                      3. Brief Details of Business / Profession / Job :{" "}
                      <b>
                        <span className="business_details">{shooterDetails.business_details}</span>
                      </b>
                    </p>
                    <p>
                      Monthly / Yearly Income:{" "}
                      <b>
                        <span className="income">{shooterDetails.income}</span>
                      </b>
                    </p>
                  </li>
                  <li className="spacer">
                    <p>4. Detail of family member staying with the applicant</p>
                    <p></p>
                    <table className="table table-bordered darkborder" id="relation_tbl">
                      <thead>
                        <tr>
                          <th>NAME</th>
                          <th>AGE</th>
                          <th>OCCUPATION</th>
                          <th>RELATION</th>
                          <th>ADDRESS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {familyDetailsArray.map((guardian: any, index: any) => (
                          <tr key={index}>
                            <td>{guardian.name || "-"}</td>
                            <td>{guardian.age || "-"}</td>
                            <td>{guardian.occupation || "-"}</td>
                            <td>{guardian.relation || "-"}</td>
                            <td>{guardian.present_address || "-"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <p></p>
                  </li>
                  <li className="spacer">
                    <p>
                      5. Whether Arms License is applied?{" "}
                      {/* <b>
                        <span className="license_applied">{shooterDetails.is_arm_license_applied}</span>
                      </b>{" "} */}
                      <b>
                        <span className="arm_result">{shooterDetails.arm_license_result}</span>
                      </b>
                    </p>
                    <p>
                      Whether Application is Pending or License was Granted / Rejected :{" "}
                      <b>
                        <span className="arm_application_status">{shooterDetails.arm_application_status}</span>
                      </b>
                    </p>
                    {/* <p className="col-md-3">Details of Arms: </p> */}
                    <p className="col-md-3">
                      {/* No:{" "} */}
                      <b>
                        <span className="license_no">{shooterDetails.license_no}</span>
                      </b>
                    </p>
                    <p className="col-md-3">
                      Issued By:{" "}
                      <b>
                        <span className="issued_by">{shooterDetails.arm_license_issued_by}</span>
                      </b>
                    </p>
                    <p className="col-md-3">
                      Valid Up to:{" "}
                      <b>
                        <span className="valid_upto">{getFormattedDate(shooterDetails.arm_license_validity)}</span>
                      </b>
                    </p>
                  </li>
                  <li className="spacer">
                    <p>6. Details Of Arms : </p>
                    <p></p>
                    <table className="table table-bordered darkborder" id="arm_tbl">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Sr.no</th>
                          <th>Caliber</th>
                        </tr>
                      </thead>
                      <tbody>
                        {armDetailsArray.armDetails.map((arm: any, index: any) => (
                          <tr key={index}>
                            <td>{arm.type || "-"}</td>
                            <td>{arm.serial_no || "-"}</td>
                            <td>{arm.caliber || "-"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <p></p>
                  </li>
                  <li className="spacer">
                    <p>
                      7. Details of arms license and weapon possessed by each above mentioned members:
                      <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={() => handleViewDocument("arm_license_proof")}>
                        <VisibilityIcon />
                      </IconButton>
                    </p>
                  </li>
                  <li className="spacer">
                    <p>
                      8. Experience in Using Firearms:{" "}
                      <b>
                        <span className="experience_in_firearms">{shooterDetails.experience_in_firearms}</span>
                      </b>
                    </p>
                    <p>
                      Details of Course Attended in Handling of Guns:{" "}
                      <b>
                        <span className="details_of_course">{shooterDetails.details_of_course}</span>
                      </b>
                    </p>
                  </li>
                  <li className="spacer">
                    <p>
                      9. Details of other sports activities if any:{" "}
                      <b>
                        <span className="details_of_sports">{shooterDetails.details_of_sports}</span>
                      </b>
                    </p>
                  </li>
                  <li className="spacer">
                    <p>
                      10. Other Information:{" "}
                      <b>
                        <span className="other_information">{shooterDetails.other_information}</span>
                      </b>{" "}
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  if (activeStep === steps.length) {
    navigate(APP_ROUTES.ADMIN_CLUB_RANGE_REGISTER);
  }

  return (
    <>
      <BackLink title="Back to shooters list" route={APP_ROUTES.ADMIN_CLUB_RANGE_REGISTER} />
      <PageContainer title="Range Usage" description="Range Usage Application">
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            xl={12}
            mb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              sx={{
                width: "100%",
                boxShadow: "2px 2px 4px #ccc",
                background: "linear-gradient(317deg, rgba(255,255,254,1) 0%, rgba(249,251,253,1) 40%)",
              }}
              p={3}
            >
              <Stepper sx={{ paddingBottom: "20px" }} activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={label} completed={!isStepSkipped(index)}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length ? null : (
                <>
                  <Box>{renderStepContent(activeStep)}</Box>
                  <Stack direction="row" justifyContent="center" spacing={3} mt={4}>
                    <Button
                      size="large"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      variant="contained"
                      color="inherit"
                      sx={{ width: '120px' }} // Adjust width as needed
                    >
                      <Typography variant="subtitle2" ml={1}>
                        Previous
                      </Typography>
                    </Button>
                    <Button
                      size="large"
                      onClick={handleNext}
                      variant="contained"
                      color={activeStep === steps.length - 1 ? "success" : "primary"}
                      sx={{ width: '120px' }} // Adjust width as needed
                    >
                      <Typography variant="subtitle2" mr={1}>
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Typography>
                    </Button>
                  </Stack>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </PageContainer>
    </>
  );
};

export default RangeSlider;
